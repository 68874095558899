<template>
  <div v-if="validRoom == true && loadedRoom == true">
    <v-card dark color="#000000" width="22vw" style="border-color: #1F2937; position: absolute; top: 2vh; left: 39vw;" outlined>
      <div class="d-flex flex-no-wrap">
        <div class="ma-3">
          <span style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 2vh;"><v-icon size="2.3vh" color="white" style="margin-bottom: .4vh;">mdi-account-group</v-icon> {{ roomId }}</span>
          <p v-if="roomInfo.started == true && roomInfo.ended == false" style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh; margin-bottom: 0px;">Game in Progress</p>
          <p v-if="roomInfo.started == true && roomInfo.ended == true" style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh; margin-bottom: 0px;">Round Over</p>
          <p v-if="roomInfo.started == false && roomInfo.ended == false" style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh; margin-bottom: 0px;">Waiting for Host..</p>
        </div>
        <v-spacer></v-spacer>
        <div class="ma-3">
          <span v-if="roomInfo.started == true" style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 1.9vh; margin-bottom: .4vh; float: right;">{{ roomInfo.currentTurnName }}'<span v-if="roomInfo.currentTurnName.charAt(roomInfo.currentTurnName.length - 1) != 's'">s</span> Turn</span>
          <br>
          <span style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 1.5vh; float: right;">{{ getTime() }} Elapsed</span>
        </div>
      </div>
    </v-card>
    <span style="width: 40vw; font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 2vh; position: absolute; top: 15vh; left: 30vw; text-align: center;" outlined>{{ notificationText }}</span>

    <v-card dark color="#000000" width="26vw" style="border-color: #1F2937; position: absolute; top: 2vh; right: 3vw;" outlined>
      <div v-for="player in roomInfo.playerData" v-bind:key="player.Id" class="d-flex flex-no-wrap">
        <v-avatar class="ma-3" height="100%" rounded>
          <v-img v-if="player.Gluno == false || player.cardsLeft > 2" src="https://cdn.discordapp.com/attachments/890547395606216724/890563134954299402/UnoLogoBW.png"></v-img>
          <v-img v-else src="https://cdn.discordapp.com/attachments/890547395606216724/890563121071128636/UnoLogo.png"></v-img>
        </v-avatar>
        <div class="ma-3">
          <span v-if="player.Id == roomInfo.currentTurn && roomInfo.ended == false && roomInfo.started == true" style="font-family: Open Sans; font-weight: bold; color: #F44336; font-size: 2vh;"><v-icon v-if="player.badges.Developer == true" size="2.2vh" color="#2196f3" style="margin-bottom: .4vh;">mdi-hammer-wrench</v-icon><v-icon v-if="player.badges.Penguin == true" size="2.2vh" color="#ff781f" style="margin-bottom: .4vh;">mdi-penguin</v-icon><v-icon v-if="player.badges.Tester == true" size="2.3vh" color="green" style="margin-bottom: .4vh;">mdi-bug</v-icon><v-icon v-if="player.Id == roomInfo.host" size="2vh" color="yellow" style="margin-bottom: .4vh;">mdi-diamond-stone</v-icon> <v-icon v-if="player.Id == roomInfo.currentTurn" size="2.3vh" color="#F44336" style="margin-bottom: .4vh;">mdi-cards</v-icon> {{ player.Name }}</span>
          <span v-else style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 2vh;"><v-icon v-if="player.badges.Developer == true" size="2.2vh" color="#2196f3" style="margin-bottom: .4vh;">mdi-hammer-wrench</v-icon><v-icon v-if="player.badges.Penguin == true" size="2.2vh" color="#ff781f" style="margin-bottom: .4vh;">mdi-penguin</v-icon><v-icon v-if="player.badges.Tester == true" size="2.3vh" color="green" style="margin-bottom: .4vh;">mdi-bug</v-icon><v-icon v-if="player.Id == roomInfo.host" size="2vh" color="yellow" style="margin-bottom: .4vh;">mdi-diamond-stone</v-icon> {{ player.Name }}</span>
          <p v-if="roomInfo.started == true && roomInfo.ended == false" style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh; margin-bottom: 0px;">{{ player.cardsLeft }} cards left to play</p>
          <p v-else style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh; margin-bottom: 0px;">Waiting for Host..</p>
        </div>
        <v-spacer></v-spacer>
        <div class="ma-3">
          <v-chip v-if="player.Id == roomInfo.upNext && roomInfo.ended == false && roomInfo.started == true" x-small label style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; margin-top: .7vh; float: right;">Up Next!</v-chip>
          <br>
          <span style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 1.5vh; float: right;">{{ player.Wins }} win<span v-if="player.Wins != 1">s</span></span>
        </div>
      </div>
    </v-card>

    <v-card dark color="#000000" width="70vw" height="34vh" style="border-color: #1F2937; position: absolute; bottom: 2vh; left: 15vw;" outlined>
      <div class="d-flex flex-no-wrap" style="height: 34vh;">
        <div class="ml-3 mt-3 mb-3" style="padding-right: 0.5vw; min-width: 11vw;">
          <span v-if="playerInfo.inRoom == roomId" style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 2vh;"><v-icon size="2.8vh" color="white" style="margin-bottom: 3.5%;">mdi-cards</v-icon> {{ playerInfo.Name }}</span>
          <span v-else style="font-family: Open Sans; font-weight: bold; color: #F44336; font-size: 2vh;"><v-icon size="2.8vh" color="#F44336" style="margin-bottom: 3.5%;">mdi-account-eye-outline</v-icon> {{ playerInfo.Name }}</span>
          <p v-if="playerInfo.inRoom == roomId && roomInfo.started == true" style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh;">{{ playerInfo.Cards.length }} cards left to play</p>
          <p v-if="playerInfo.inRoom == roomId && roomInfo.started == false" style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh;">Round starting soon</p>
          <p v-if="playerInfo.inRoom != roomId" style="font-family: Open Sans; font-weight: regular; color: #F44336; font-size: 1.6vh;">Spectating Room</p>
          <p style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh; bottom: 0;">Games Played:<br><b>{{ playerInfo.gamesPlayed }} games played</b></p>
          <p style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh; bottom: 0;">Total Wins:<br><b>{{ playerInfo.Wins }} win<span v-if="playerInfo.Wins != 1">s</span></b></p>
          <v-btn v-if="playerInfo.inRoom == roomId" color="#D72600" :disabled="playerInfo.Cards.length !== 2" @click="gluno()" style="width: 9vw;">GLUno!</v-btn>
        </div>
        <div v-if="playerInfo.inRoom == roomId && roomInfo.started == true" id="cardDeck" class="cardDeck" style="overflow-x: auto; overflow-y: auto; margin-top: 1.8vh; display: flex;">
          <img v-for="card in playerInfo.Cards" v-bind:key="card.key" :class="card.color + 'Card'" @click="playCard(card.color, card.type, card.key)" :src="cards[card.color][card.type]" style="height: 30vh; width: auto; margin-right: 0.4vw;"/>
        </div>
        <div v-if="playerInfo.inRoom == roomId && roomInfo.started == false" style="min-width: 70vw; margin-left: -12vw;">
          <span style="display: table; font-family: Open Sans; font-weight: bold; color: #CCCCCC; margin-left: auto; margin-right: auto; margin-top: 10vh; text-align: center;">You're in! Get ready to play!<br><span style="color: #F44336 !important">{{ roomInfo.hostName }}</span> will start the round soon.</span>
        </div>
        <div v-if="playerInfo.inRoom != roomId" style="min-width: 70vw; margin-left: -12vw;">
          <span style="display: table; font-family: Open Sans; font-weight: bold; color: #CCCCCC; margin-left: auto; margin-right: auto; margin-top: 10vh; text-align: center;">You are currently spectating this room<br>Click <a :href="domain" style="color: #F44336 !important">here</a> to go back to rooms</span>
        </div>
      </div>
    </v-card>

    <div v-if="roomInfo.ended == true || roomInfo.started == false" style="width: 100vw; height: 100vh; background-color: rgb(0, 0, 0); opacity: 0.5; position: absolute; top: 0; left:0; z-index: 2;"></div>
    <v-card v-if="roomInfo.ended == true" dark color="#000000" width="auto" height="auto" style="border-color: #1F2937; margin-top: 15vh; display: table; margin: auto; z-index: 3;" outlined>
        <div class="d-flex flex-no-wrap" style="height: auto;">
          <div class="ml-3 mt-3 mb-3" style="padding-right: 0.5vw; min-width: 11vw;">
            <p v-if="playerInfo.inRoom == roomId" style="font-family: Open Sans; font-weight: regular; color: #F44336; font-size: 1.6vh;">Round Over<br><b>You finished {{ playerInfo.Ranking }} place</b></p>
            <p v-if="playerInfo.inRoom != roomId" style="font-family: Open Sans; font-weight: regular; color: #F44336; font-size: 1.6vh;">Round Over<br><b>You are spectating</b></p>
            <p style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh; bottom: 0;">Games Played:<br><b>{{ playerInfo.gamesPlayed }} games played</b></p>
            <p style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh; bottom: 0;">Total Wins:<br><b>{{ playerInfo.Wins }} win<span v-if="playerInfo.Wins != 1">s</span></b></p>
            <v-img src="https://cdn.discordapp.com/attachments/890547395606216724/890563121071128636/UnoLogo.png" style="max-width: 20vh;"></v-img>
          </div>
          <div style="margin-top: 1.8vh; margin-right: 1vw; margin-bottom: 2.5vh;">
            <span style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 2.7vh;"><v-icon size="2.6vh" color="#FFFFFF" style="margin-bottom: 0.5vh;">mdi-trophy</v-icon> Round Results ({{ getTime() }})</span>
            <div v-for="rank in roomInfo.ranking" v-bind:key="rank.Ranked.charAt(0)" class="d-flex flex-no-wrap">
              <div>
                <span style="margin-top: 0.5vh; font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 5.1vh; float: right;">{{ rank.Ranked.charAt(0) }}.</span>
              </div>
              <div class="ma-3">
                <span style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 2vh;"><v-icon v-if="roomInfo.playerData[rank.Name].badges.Developer == true" size="2.2vh" color="#2196f3" style="margin-bottom: .4vh;">mdi-hammer-wrench</v-icon><v-icon v-if="roomInfo.playerData[rank.Name].badges.Penguin == true" size="2.2vh" color="#ff781f" style="margin-bottom: .4vh;">mdi-penguin</v-icon><v-icon v-if="roomInfo.playerData[rank.Name].badges.Tester == true" size="2.3vh" color="green" style="margin-bottom: .4vh;">mdi-bug</v-icon><v-icon v-if="roomInfo.playerData[rank.Name].Id == roomInfo.host" size="2vh" color="yellow" style="margin-bottom: .4vh;">mdi-diamond-stone</v-icon> {{ rank.Name }}</span>
                <p style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh; margin-bottom: 0px;">Ended with {{ roomInfo.playerData[rank.Name].cardsLeft }} cards left to play</p>
              </div>
              <v-spacer></v-spacer>
              <div class="ma-3">
                <span class="mt-3" style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 1.5vh; float: right;">{{ roomInfo.playerData[rank.Name].Wins }} win<span v-if="roomInfo.playerData[rank.Name].Wins != 1">s</span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 ml-3 mr-3">
          <span v-if="roomInfo.hostName == playerInfo.Name" class="mt-3" style="display: table; margin: auto; font-family: Open Sans; font-weight: bold; color: #CCCCCC; font-size: 1.7vh;"><v-icon size="1.5vh" color="yellow" style="margin-bottom: .1vh;">mdi-diamond-stone</v-icon><a @click="restartRound()" style="color: #ffeb3b !important;"> Restart Round</a> or <a :href="domain" style="color: #F44336 !important;">go back</a> to the other rooms</span>
          <span v-else class="mt-3" style="display: table; margin: auto; font-family: Open Sans; font-weight: bold; color: #CCCCCC; font-size: 1.7vh;">Ask <v-icon size="1.5vh" color="yellow" style="margin-bottom: .1vh;">mdi-diamond-stone</v-icon><span style="color: #ffeb3b !important;"> {{ roomInfo.hostName }}</span> to restart the round or <a :href="domain" style="color: #F44336 !important;">go back</a> to the other rooms</span>
        </div>
      </v-card>

    <v-card v-if="roomInfo.started == false" dark color="#000000" width="auto" height="auto" style="border-color: #1F2937; margin-top: 15vh; display: table; margin: auto; z-index: 3;" outlined>
        <div class="d-flex flex-no-wrap" style="height: auto;">
          <div style="margin-top: 1.8vh; margin-left: 1vw; margin-right: 1vw; margin-bottom: 2.5vh;">
            <span style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 2.7vh;"><v-icon size="2.6vh" color="#FFFFFF" style="margin-bottom: 0.5vh;">mdi-account-switch</v-icon> Waiting for Players ({{roomInfo.count}}/4)</span>
            <div v-for="player in roomInfo.playerData" v-bind:key="player.Id" class="d-flex flex-no-wrap mb-n5">
              <div>
                <span style="margin-bottom: 0.2vh; font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 5.1vh; float: right;">-</span>
              </div>
              <div class="ml-3 mt-3 mr-3">
                <span style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 2vh;"><v-icon v-if="roomInfo.playerData[player.Name].badges.Developer == true" size="2.2vh" color="#2196f3" style="margin-bottom: .4vh;">mdi-hammer-wrench</v-icon><v-icon v-if="roomInfo.playerData[player.Name].badges.Penguin == true" size="2.2vh" color="#ff781f" style="margin-bottom: .4vh;">mdi-penguin</v-icon><v-icon v-if="roomInfo.playerData[player.Name].badges.Tester == true" size="2.3vh" color="green" style="margin-bottom: .4vh;">mdi-bug</v-icon><v-icon v-if="roomInfo.playerData[player.Name].Id == roomInfo.host" size="2vh" color="yellow" style="margin-bottom: .4vh;">mdi-diamond-stone</v-icon> {{ player.Name }}</span>
                <p style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 1.6vh;">Ready to play!</p>
              </div>
              <v-spacer></v-spacer>
              <div class="ml-3 mt-3 mr-3">
                <span class="mt-3" style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 1.5vh; float: right;">{{ roomInfo.playerData[player.Name].Wins }} win<span v-if="roomInfo.playerData[player.Name].Wins != 1">s</span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 ml-3 mr-3">
          <span v-if="roomInfo.hostName == playerInfo.Name" class="mt-3" style="display: table; margin: auto; font-family: Open Sans; font-weight: bold; color: #CCCCCC; font-size: 1.7vh;"><v-icon size="1.5vh" color="yellow" style="margin-bottom: .1vh;">mdi-diamond-stone</v-icon><a @click="startRound()" style="color: #ffeb3b !important;"> Start Round</a> or <a :href="domain" style="color: #F44336 !important;">go back</a> to the other rooms</span>
          <span v-if="roomInfo.hostName != playerInfo.Name && playerInfo.inRoom != roomId" class="mt-3" style="display: table; margin: auto; font-family: Open Sans; font-weight: bold; color: #CCCCCC; font-size: 1.7vh; text-align: center;">You are currently spectating.<br><a v-if="roomInfo.count < 4" :href="domain + 'room/' + roomId" style="color: #F44336 !important;">Join this round</a></span>
          <span v-if="roomInfo.hostName != playerInfo.Name && playerInfo.inRoom == roomId" class="mt-3" style="display: table; margin: auto; font-family: Open Sans; font-weight: bold; color: #CCCCCC; font-size: 1.7vh; text-align: center;"><v-icon size="1.5vh" color="yellow" style="margin-bottom: .1vh;">mdi-diamond-stone</v-icon><span style="color: #ffeb3b !important;"> {{ roomInfo.hostName }}</span> will start the round soon.<br><a :href="domain" style="color: #F44336 !important;">Go back</a> to the other rooms</span>
        </div>
      </v-card>

    <v-card dark color="#000000" style="position: absolute; bottom: 42vh; left: 39.3vw;" outlined>
      <v-card v-if="colorPicker" style="width: 21.5vw; height: 10vh; margin-bottom: 2vh;">
        <span style="margin-top: 1vh; margin-left: .5vw; min-width: 21.5vw;">Select a color</span><br>
        <v-card @click="playCard(colorPickerCard.color, colorPickerCard.type, colorPickerCard.key, 'Red')" color="#ED1C24" style="width: 4.75vw; margin-bottom: .5vh; margin-left: .5vw; height: 6vh; display: inline-block;"></v-card>
        <v-card @click="playCard(colorPickerCard.color, colorPickerCard.type, colorPickerCard.key, 'Yellow')" color="#FFDE00" style="width: 4.75vw; margin-bottom: .5vh; margin-left: .5vw; height: 6vh; display: inline-block;"></v-card>
        <v-card @click="playCard(colorPickerCard.color, colorPickerCard.type, colorPickerCard.key, 'Green')" color="#00A651" style="width: 4.75vw; margin-bottom: .5vh; margin-left: .5vw; height: 6vh; display: inline-block;"></v-card>
        <v-card @click="playCard(colorPickerCard.color, colorPickerCard.type, colorPickerCard.key, 'Blue')" color="#0095DA" style="width: 4.75vw; margin-bottom: .5vh; margin-left: .5vw; height: 6vh; display: inline-block;"></v-card>
      </v-card>
      <img :src="cards[roomInfo.currentCard['Color']][roomInfo.currentCard['Type']]" style="max-width: 10vw;"/>
      <img v-if="roomInfo.outOfDeckCards == false && playerInfo.inRoom == roomId" :src="cards['Black']['Uno']" class="BlackCard" @click="grabCard()" style="max-width: 10vw; margin-left: 1.4vw;"/>
      <img v-else :src="cards['Black']['UnoBW']" style="max-width: 10vw; margin-left: 1.5vw;"/>
    </v-card>
  </div>
</template>

<script>
import io from "socket.io-client";
  export default {
    data: () => ({
      audios: {
        gluno: new Audio('https://cdn.discordapp.com/attachments/891940809270763530/891986283696623656/GlunoTygo.mp3'),
        yourTurn: new Audio('https://cdn.discordapp.com/attachments/890547395606216724/892122934938828891/yourTurn.mp3'),
        haha: new Audio('https://cdn.discordapp.com/attachments/890547395606216724/892144296277467196/Haha.mp3')
      },
      loadedRoom: false,
      validRoom: false,
      roomId: "",
      notificationText: "",
      playedHaha: false,
      domain: "",

      colorPicker: false,
      colorPickerCard: {},

      cards: {
        "Red": {
          "0": "https://cdn.discordapp.com/attachments/890547395606216724/890552890589069322/Red_0.png",
          "1": "https://cdn.discordapp.com/attachments/890547395606216724/890552891629264916/Red_1.png",
          "2": "https://cdn.discordapp.com/attachments/890547395606216724/890552893051121706/Red_2.png",
          "3": "https://cdn.discordapp.com/attachments/890547395606216724/890552894917603368/Red_3.png",
          "4": "https://cdn.discordapp.com/attachments/890547395606216724/890552897731977216/Red_4.png",
          "5": "https://cdn.discordapp.com/attachments/890547395606216724/890552899883638784/Red_5.png",
          "6": "https://cdn.discordapp.com/attachments/890547395606216724/890552902849007646/Red_6.png",
          "7": "https://cdn.discordapp.com/attachments/890547395606216724/890552905181069342/Red_7.png",
          "8": "https://cdn.discordapp.com/attachments/890547395606216724/890552907504713728/Red_8.png",
          "9": "https://cdn.discordapp.com/attachments/890547395606216724/890552909840920636/Red_9.png",
          "Plus": "https://cdn.discordapp.com/attachments/890547395606216724/890556694449504266/Red_Plus.png",
          "Block": "https://cdn.discordapp.com/attachments/890547395606216724/890556693291860028/Red_Block.png",
          "Reverse": "https://cdn.discordapp.com/attachments/890547395606216724/890556696005603378/Red_Reverse.png",
          "Four": "https://cdn.discordapp.com/attachments/890547395606216724/898123871272697886/Wild_Plus_Red.png",
        },
        "Green": {
          "0": "https://cdn.discordapp.com/attachments/890547395606216724/890554481002053642/Green_0.png",
          "1": "https://cdn.discordapp.com/attachments/890547395606216724/890554483048869938/Green_1.png",
          "2": "https://cdn.discordapp.com/attachments/890547395606216724/890554484646903828/Green_2.png",
          "3": "https://cdn.discordapp.com/attachments/890547395606216724/890554486299439134/Green_3.png",
          "4": "https://cdn.discordapp.com/attachments/890547395606216724/890554487805210634/Green_4.png",
          "5": "https://cdn.discordapp.com/attachments/890547395606216724/890554489482903562/Green_5.png",
          "6": "https://cdn.discordapp.com/attachments/890547395606216724/890554491177414666/Green_6.png",
          "7": "https://cdn.discordapp.com/attachments/890547395606216724/890554492859351091/Green_7.png",
          "8": "https://cdn.discordapp.com/attachments/890547395606216724/890554494843248670/Green_8.png",
          "9": "https://cdn.discordapp.com/attachments/890547395606216724/890554497233993748/Green_9.png",
          "Plus": "https://cdn.discordapp.com/attachments/890547395606216724/890559032757211196/Green_Plus.png",
          "Block": "https://cdn.discordapp.com/attachments/890547395606216724/890559031700250674/Green_Block.png",
          "Reverse": "https://cdn.discordapp.com/attachments/890547395606216724/890559034279743528/Green_Reverse.png",
          "Four": "https://cdn.discordapp.com/attachments/890547395606216724/898123864763158578/Wild_Plus_Green.png",
        },
        "Blue": {
          "0": "https://cdn.discordapp.com/attachments/890547395606216724/890555909233840128/Blue_0.png",
          "1": "https://cdn.discordapp.com/attachments/890547395606216724/890555910773153832/Blue_1.png",
          "2": "https://cdn.discordapp.com/attachments/890547395606216724/890555912350228520/Blue_2.png",
          "3": "https://cdn.discordapp.com/attachments/890547395606216724/890555913763708928/Blue_3.png",
          "4": "https://cdn.discordapp.com/attachments/890547395606216724/890555915240095814/Blue_4.png",
          "5": "https://cdn.discordapp.com/attachments/890547395606216724/890555916787785768/Blue_5.png",
          "6": "https://cdn.discordapp.com/attachments/890547395606216724/890555918364856340/Blue_6.png",
          "7": "https://cdn.discordapp.com/attachments/890547395606216724/890555920902422568/Blue_7.png",
          "8": "https://cdn.discordapp.com/attachments/890547395606216724/890555921904832552/Blue_8.png",
          "9": "https://cdn.discordapp.com/attachments/890547395606216724/890555923666444298/Blue_9.png",
          "Plus": "https://cdn.discordapp.com/attachments/890547395606216724/890559060980662282/Blue_Plus.png",
          "Block": "https://cdn.discordapp.com/attachments/890547395606216724/890559060083085333/Blue_Block.png",
          "Reverse": "https://cdn.discordapp.com/attachments/890547395606216724/890559062037631066/Blue_Reverse.png",
          "Four": "https://cdn.discordapp.com/attachments/890547395606216724/898123863446147092/Wild_Plus_Blue.png",
        },
        "Yellow": {
          "0": "https://cdn.discordapp.com/attachments/890547395606216724/890553058063450182/Yellow_0.png",
          "1": "https://cdn.discordapp.com/attachments/890547395606216724/890553060231876619/Yellow_1.png",
          "2": "https://cdn.discordapp.com/attachments/890547395606216724/890553062735904848/Yellow_2.png",
          "3": "https://cdn.discordapp.com/attachments/890547395606216724/890553065059520562/Yellow_3.png",
          "4": "https://cdn.discordapp.com/attachments/890547395606216724/890553067374780416/Yellow_4.png",
          "5": "https://cdn.discordapp.com/attachments/890547395606216724/890553069849415690/Yellow_5.png",
          "6": "https://cdn.discordapp.com/attachments/890547395606216724/890553072290496552/Yellow_6.png",
          "7": "https://cdn.discordapp.com/attachments/890547395606216724/890553074769330206/Yellow_7.png",
          "8": "https://cdn.discordapp.com/attachments/890547395606216724/890553077248192572/Yellow_8.png",
          "9": "https://cdn.discordapp.com/attachments/890547395606216724/890553079747989504/Yellow_9.png",
          "Plus": "https://cdn.discordapp.com/attachments/890547395606216724/890558999009853451/Yellow_Plus.png",
          "Block": "https://cdn.discordapp.com/attachments/890547395606216724/890558998112268328/Yellow_Block.png",
          "Reverse": "https://cdn.discordapp.com/attachments/890547395606216724/890559000662405120/Yellow_Reverse.png",
          "Four": "https://cdn.discordapp.com/attachments/890547395606216724/898123874846269460/Wild_Plus_Yellow.png",
        },
        "Black": {
          "Wild": "https://cdn.discordapp.com/attachments/890547395606216724/890559116307755038/Wild.png",
          "Four": "https://cdn.discordapp.com/attachments/890547395606216724/890559117989658654/Wild_Plus.png",
          "Uno": "https://cdn.discordapp.com/attachments/890547395606216724/890560240771301386/Uno.png",
          "UnoBW": "https://cdn.discordapp.com/attachments/890547395606216724/890686089369636905/UnoBW.png",
        },
        "Grey": {
          "Question": "https://cdn.discordapp.com/attachments/890547395606216724/890559115225616394/Question.png",
        },
    },
      socket: io("backend.gl-uno.com"),
    }),
    mounted: function(){
      let processData = this;

      if (localStorage.getItem('userToken')) {
        processData.socket.emit("validToken", { token: localStorage.getItem('userToken') }, function (data) {
          if (data == false) {
            localStorage.removeItem('userToken')
            window.location.href = window.location.href.split('room')[0]
          }
        })
      } else {
        window.location.href = window.location.href.split('room')[0]
      }

      processData.domain = window.location.href.split('room')[0]

      processData.socket.emit("getData", { token: localStorage.getItem('userToken') }, function (data) {
          if (data) {
              for (var key in data) {
                processData[key] = data[key];
              }
              if (window.location.pathname.split('/')[1] === "room") {
                processData.roomId = window.location.pathname.split('/')[2]
                Object.keys(data["rooms"]).forEach(function(k) {
                  if (k == processData.roomId) {
                    processData.roomInfo = data["rooms"][k].roomInfo
                    processData.validRoom = true;
                  }
                })
                if (processData.validRoom == false) {
                  window.location.href = window.location.href.split('room')[0]
                }
              }
              processData.loadedRoom = true;
              if (processData.roomInfo.started == false) {
                processData.socket.emit("setInRoom", { token: localStorage.getItem('userToken'), room: processData.roomId }, function () {

                });
              }
          } else {
              console.log("No Data")
          }
      });

      setInterval(function() {
        processData.updateData();
      }, 1000);
    },
    methods: {
      updateData() {
        let processData = this;
        processData.socket.emit("getData", { token: localStorage.getItem('userToken') }, function (data) {
            if (data) {
              let oldTurn = processData.roomInfo.currentTurn;
              let oldGluno = [];
              Object.keys(processData.roomInfo.playerData).forEach(function(k) {
                oldGluno.push(processData.roomInfo.playerData[k].Gluno);
              })
              for (var key in data) {
                processData[key] = data[key];
              }
              if (window.location.pathname.split('/')[1] === "room") {
                processData.roomId = window.location.pathname.split('/')[2]
                processData.validRoom = false;
                Object.keys(data["rooms"]).forEach(function(k) {
                  if (k == processData.roomId) {
                    processData.roomInfo = data["rooms"][k].roomInfo
                    processData.validRoom = true;
                    let newGluno = [];
                    Object.keys(processData.roomInfo.playerData).forEach(function(k) {
                      newGluno.push(processData.roomInfo.playerData[k].Gluno);
                    })
                    let glunoChanged = false
                    for (var i = 0; i < oldGluno.length;) {
                      if (oldGluno[i] != newGluno[i]) {
                        if (oldGluno[i] == false && newGluno[i] == true) {
                          glunoChanged = true
                        }
                      }
                      i++
                    }
                    if (glunoChanged) {
                      processData.audios.gluno.play()
                    }
                    if (processData.roomInfo.currentTurn != oldTurn && processData.roomInfo.currentTurnName == processData.playerInfo.Name) {
                      processData.audios.yourTurn.play()
                      if (processData.roomInfo.currentCard.Type == "Plus") {
                        if (processData.playedHaha == false) {
                          processData.playedHaha = true;
                          processData.audios.haha.play()
                        }
                      } else {
                        processData.playedHaha = false;
                      }
                    }
                  }
                })
                if (processData.validRoom == false) {
                  window.location.href = window.location.href.split('room')[0]
                }
              }
          } else {
              console.log("No Data")
          }
        });
        processData.socket.emit("stillHere", { token: localStorage.getItem('userToken') }, function () {
        });
      },
      getTime() {
        let processData = this;
        var minutes = Math.floor(processData.roomInfo.timeElapsed / 60);
        var seconds = processData.roomInfo.timeElapsed % 60;
        return (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
      },
      grabCard() {
        let processData = this;
        processData.socket.emit("grabCard", { token: localStorage.getItem('userToken'), room: processData.roomId }, function () {
          processData.updateData()
        });
      },
      restartRound() {
        let processData = this;
        processData.socket.emit("restartRound", { token: localStorage.getItem('userToken'), room: processData.roomId }, function () {
        });
      },
      startRound() {
        let processData = this;
        processData.socket.emit("startRound", { token: localStorage.getItem('userToken'), room: processData.roomId }, function () {
        });
      },
      playCard(Color, Type, Key, Options) {
        let processData = this;
        if (Color != "Black") {
          processData.colorPicker = false;
          processData.socket.emit("playCard", { token: localStorage.getItem('userToken'), card: { color: Color, type: Type, key: Key }, room: processData.roomId }, function () {
            processData.updateData()
          });
        } else {
          if (!Options) {
            processData.colorPicker = true;
            processData.colorPickerCard.color = Color
            processData.colorPickerCard.type = Type
            processData.colorPickerCard.key = Key
          } else {
            processData.colorPicker = false;
            processData.socket.emit("playCard", { token: localStorage.getItem('userToken'), card: { color: Options, type: Type, key: Key }, room: processData.roomId }, function () {
              processData.updateData()
            });
          }
        }
      },
      gluno() {
        let processData = this;
        processData.socket.emit("gluno", { token: localStorage.getItem('userToken'), room: processData.roomId }, function () {
          // Object.keys(processData.roomInfo.playerData).forEach(function(k) {
          //   if (k == processData.playerInfo.Name && processData.roomInfo.playerData[k].Gluno == false) {
          //     var audio = new Audio('https://cdn.discordapp.com/attachments/891940809270763530/891986283696623656/GlunoTygo.mp3')
          //     audio.play()
          //   }
          // })
          processData.updateData()
        });
      },
    }
  }
</script>

<style lang="scss">
html::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cardDeck::-webkit-scrollbar {
  display: none;
}

.cardDeck {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.YellowCard:hover {
  border: 5px solid #FFDE00;
  border-radius: 20px;
}

.RedCard:hover {
  border: 5px solid #ED1C24;
  border-radius: 20px;
}

.BlueCard:hover {
  border: 5px solid #0095DA;
  border-radius: 20px;
}

.GreenCard:hover {
  border: 5px solid #00A651;
  border-radius: 20px;
}

.BlackCard:hover {
  border: 5px solid #000000;
  border-radius: 20px;
}

.GreyCard:hover {
  border: 5px solid #6D6E71;
  border-radius: 20px;
}
</style>